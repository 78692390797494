<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                size="sm"
                                v-model="form.faculty_code"
                                :validate-error="errors[0]"
                            ></faculty-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="department_codes" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('department_code')">
                            <department-selectbox
                                v-model="form.department_codes"
                                :faculty_code="form.faculty_code"
                                :validate-error="errors[0]"
                            >
                            </department-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="program_code" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                :faculty_code="form.faculty"
                                :department_code="form.department_codes"
                                :validateError="errors[0]"
                                v-model="form.program_code">
                            </program-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"/>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import CourseAutoComplete from '@/components/interactive-fields/CourseAutoComplete';
import ElectivePoolSelectbox from '@/components/interactive-fields/ElectivePoolSelectbox';
import SemesterNumberedSelectbox from '@/components/interactive-fields/SemesterNumberedSelectbox';
import SlotTypeSelectbox from '@/components/interactive-fields/SlotTypeSelectbox';

// Services

import StudentSlotService from '@/services/StudentSlotService';
// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {

        ValidationProvider,
        ValidationObserver
    },
    props: {
        studentProgramId: {
            type: Number
        },

    },
    data() {
        return {
            form: {}
        }
    },
    mounted() {
        this.$refs.formModalValidate.reset()
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                let formData = {
                    program_code: this.form.program_code,
                    student_program_id: this.studentProgramId


                }
                return StudentSlotService.changeProgram(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$refs.formModalValidate.reset();
                        this.$emit('closedChangeProgramModal', true);
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })


            }
        }
    }
}
</script>

