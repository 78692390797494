import { render, staticRenderFns } from "./changeProgramForm.vue?vue&type=template&id=2bd87be6&"
import script from "./changeProgramForm.vue?vue&type=script&lang=js&"
export * from "./changeProgramForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports