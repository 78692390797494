// Event Bus
import {EventBus} from '@/main'

// SweetAlert
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
// Plugins
import i18n from '@/plugins/i18n'
import moment from "moment"

// Services
import StudentDocumentService from '@/services/StudentDocumentService'

// Helpers
import showErrors from '@/helpers/showErrors'

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ffffff',
    icon: 'question'
}
Vue.use(VueSweetalert2, options);

export default function (user) {
    var documents = [];

    // Transcript
    documents.push({
        text: i18n.t('transcript'),
        class: 'ri-file-list-line',
        permission: 'studentdocument_transcript',
        language: ['EN'],
        callback: (lang) => {
            StudentDocumentService.downloadTranscript(user.id, lang)
                .then(response => {
                    let fileName = user.student_number + '-' + i18n.t('file_name_transcript') + '-' + lang + '.pdf'
                    EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                })
                .catch(e => {
                    showErrors(e, null, true)
                })
        }
    })
    if (user.student_status && user.student_status != "102") {
        // Student Certificate
        documents.push({
            text: i18n.t('student_certificate'),
            class: 'ri-article-line',
            permission: 'studentdocument_studentcertificate',
            language: ['EN'],
            callback: (lang) => {
                EventBus.$emit('closeDocumentModal', true);
                Vue.swal
                    .fire({
                        input: 'textarea',
                        inputLabel: i18n.t('note_you_want_to_add'),
                        inputValue: '',
                        inputPlaceholder: i18n.t('note'),
                        inputAttributes: {
                            'aria-label': i18n.t('note')
                        },
                        showCancelButton: true
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            StudentDocumentService.downloadStudentCertificate(user.id, lang, result.value)
                                .then(response => {
                                    let fileName = user.student_number + '-' + i18n.t('file_name_student_certificate') + '-' + lang + '.pdf';
                                    EventBus.$emit('pdfViewerModalShow', {
                                        pdfFileData: response.data,
                                        pdfFileName: fileName
                                    });
                                })
                                .catch(e => {
                                    showErrors(e, null, true)
                                })
                        }
                    })
            }
        })
    }

    if (user.student_status && user.student_status !="102") {
        // Course Schedule
        documents.push({
            text: i18n.t('course_schedule'),
            class: 'ri-article-line',
            permission: 'studentdocument_courseschedule',
            language: ['EN'],
            callback: (lang) => {
                StudentDocumentService.downloadCourseSchedule(user.id, lang)
                    .then(response => {
                        let fileName = user.student_number + '-' + i18n.t('file_name_course_schedule') + '-' + lang + '.pdf';
                        EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                    })
                    .catch(e => {
                        showErrors(e, null, true)
                    })
            }
        })
    }

    // Exam Schedule
    // documents.push({
    //     text: i18n.t('exam_schedule1'),
    //     class: 'ri-article-line',
    //     permission: 'studentdocument_examschedule',
    //     language: ['EN'],
    //     callback: (lang) => {
    //         StudentDocumentService.downloadExamSchedule(user.student_number, lang)
    //             .then(response => {
    //                 let fileName = user.student_number + '-' + i18n.t('file_name_exam_schedule') + '-' + lang + '.pdf'
    //                 EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
    //             })
    //             .catch(e => {
    //                 showErrors(e, null, true)
    //             })
    //     }
    // })

    // School Certificate
    // documents.push({
    //     text: i18n.t('school_certificate'),
    //     class: 'ri-article-line',
    //     permission: 'studentdocument_schoolcertificate',
    //     language: ['EN'],
    //     callback: (lang) => {
    //         StudentDocumentService.downloadSchoolCertificate(user.id, lang)
    //             .then(response => {
    //                 let fileName = user.student_number + '-' + i18n.t('file_name_school_certificate') + '-' + lang + '.pdf'
    //                 EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
    //             })
    //             .catch(e => {
    //                 showErrors(e, null, true)
    //             })
    //     }
    // })

    // New Password
    if (user.student_status && user.student_status != "102") {
        documents.push({
            text: i18n.t('new_password'),
            class: 'ri-article-line',
            permission: 'studentdocument_newpassword',
            language: ['EN'],
            callback: (lang) => {
                StudentDocumentService.downloadNewPassword(user.student_number, lang)
                    .then(response => {
                        let fileName = user.student_number + '-' + i18n.t('file_name_new_password') + '-' + lang + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                    })
                    .catch(e => {
                        showErrors(e, null, true)
                    })
            }
        })
    }

    // Prep Transcript
    // documents.push({
    //     text: i18n.t('preparatory_notes'),
    //     class: 'ri-article-line',
    //     permission: 'studentdocument_preptranscript',
    //     language: ['EN'],
    //     callback: (lang) => {
    //         StudentDocumentService.downloadPrepTranscript(user.id, lang)
    //             .then(response => {
    //                 let fileName = user.student_number + '-' + i18n.t('file_name_prep_transcript') + '-' + lang + '.pdf'
    //                 EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
    //             })
    //             .catch(e => {
    //                 showErrors(e, null, true)
    //             })
    //     }
    // })


    // Language Proficiency
    // documents.push({
    //     text: i18n.t('language_proficiency_certificate'),
    //     class: 'ri-article-line',
    //     permission: 'studentdocument_languageproficiency',
    //     language: ['EN'],
    //     callback: (lang) => {
    //         StudentDocumentService.downloadLanguageProficiency(user.id, lang)
    //             .then(response => {
    //                 let fileName = user.student_number + '-' + i18n.t('file_name_language_proficiency') + '-' + lang + '.pdf'
    //                 EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
    //             })
    //             .catch(e => {
    //                 showErrors(e, null, true)
    //             })
    //     }
    // })

    // Language Proficiency
    // documents.push({
    //     text: i18n.t('placement_exam') + ' - ' + i18n.t('entry_document'),
    //     class: 'ri-article-line',
    //     permission: 'studentdocument_englishplacementexam',
    //     language: ['EN'],
    //     callback: (lang) => {
    //         StudentDocumentService.downloadEnglishPlacementExam(user.id, lang)
    //             .then(response => {
    //                 let fileName = user.student_number + '-' + i18n.t('file_name_language_proficiency') + '.pdf'
    //                 EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
    //             })
    //             .catch(e => {
    //                 showErrors(e, null, true)
    //             })
    //     }
    // })

    // Welcome Letter
    // documents.push({
    //     text: i18n.t('welcome_letter'),
    //     class: 'ri-article-line',
    //     permission: 'studentdocument_welcome',
    //     language: ['EN'],
    //     callback: (lang) => {
    //         StudentDocumentService.downloadWelcomeLetter(user.student_number, lang)
    //             .then(response => {
    //                 let fileName = user.student_number + '-' + i18n.t('file_name_welcome_letter') + '-' + lang + '.pdf'
    //                 EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
    //             })
    //             .catch(e => {
    //                 showErrors(e, null, true)
    //             })
    //     }
    // })

    // Disipline Registry
    // documents.push({
    //     text: i18n.t('discipline_registry'),
    //     class: 'ri-article-line',
    //     permission: 'studentdocument_discipline',
    //     language: ['EN'],
    //     callback: (lang) => {
    //         EventBus.$emit('closeDocumentModal', true)
    //
    //         Vue.swal
    //             .fire({
    //                 input: 'textarea',
    //                 inputLabel: i18n.t('note_you_want_to_add'),
    //                 inputValue: '',
    //                 inputPlaceholder: i18n.t('note_for_document'),
    //                 inputAttributes: {
    //                     'aria-label': i18n.t('note_for_document')
    //                 },
    //                 showCancelButton: true
    //             })
    //             .then((result) => {
    //                 if (result.isConfirmed) {
    //                     const data = {
    //                         language: lang.toUpper(),
    //                         note: result.value ?? null
    //                     }
    //
    //                     StudentDocumentService.downloadDisciplineRegistry(user.id, data)
    //                         .then(response => {
    //                             let fileName = user.student_number + '-' + i18n.t('file_name_transcript') + '-' + lang + '.pdf'
    //                             EventBus.$emit('pdfViewerModalShow', {
    //                                 pdfFileData: response.data,
    //                                 pdfFileName: fileName
    //                             })
    //                         })
    //                         .catch(e => {
    //                             showErrors(e, null, true)
    //                         })
    //                 }
    //             })
    //     }
    // })

    // Mezun
    if (user.student_status && user.student_status == "102") {
        // Diploma
        documents.push({
            text: i18n.t('diploma'),
            class: 'ri-article-line',
            permission: 'studentdocument_diploma',
            language: ['EN'],
            callback: () => {
                let formData =
                    {
                        language: 'EN',
                        response_type: 'pdf',
                    };
                StudentDocumentService.downloadDiploma(user.id, formData)
                    .then(response => {
                        let fileName = user.student_number + '-' + i18n.t('diploma') + '-' + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                    })
                    .catch(e => {
                        showErrors(e, null, true)
                    })
            }
        })
    }


    // Diploma Annexes
    // documents.push({
    //     text: i18n.t('diploma_annexes'),
    //     class: 'ri-article-line',
    //     permission: 'studentdocument_diplomasupplement',
    //     language: ['EN'],
    //     callback: () => {
    //         StudentDocumentService.downloadDiplomaAnnexes(user.id)
    //             .then(response => {
    //                 EventBus.$emit('htmlViewerModalShow', response.data);
    //             })
    //             .catch(e => {
    //                 showErrors(e, null, true)
    //             })
    //     }
    // })

    // Temporary Graduation
    // documents.push({
    //     text: i18n.t('temporary_graduation'),
    //     class: 'ri-article-line',
    //     permission: 'studentdocument_temporarygraduationcertificate',
    //     language: ['EN'],
    //     callback: () => {
    //         StudentDocumentService.downloadTemporaryGraduation(user.id)
    //             .then(response => {
    //                 EventBus.$emit('htmlViewerModalShow', response.data);
    //             })
    //             .catch(e => {
    //                 showErrors(e, null, true)
    //             })
    //     }
    // })


// Minor-Certificate
if (user && user.registration_type && user.registration_type == '212') {
    documents.push({
        text: i18n.t('minor_certificate'),
        class: 'ri-article-line',
        permission: 'studentdocument_minorcertificate',
        language: ['EN'],
        callback: () => {
            StudentDocumentService.downloadMinorCertificate(user.id)
                .then(response => {
                    let fileName = user.student_number + '-' + i18n.t('minor_certificate') + '.pdf'
                    EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                })
                .catch(e => {
                    showErrors(e, null, true)
                })
        }
    })
}

// Erkek
if (user.gender == 'Erkek' && user.nationality_code == 'en') {
    if (['YL', 'D'].includes(user.program_level)) {
        // Dispatch Postponement
        documents.push({
            text: i18n.t('dispatch_postponement_certificate'),
            class: 'ri-article-line',
            permission: 'studentdocument_dispatchpostponement',
            language: ['EN'],
            callback: (lang) => {
                EventBus.$emit('openPostponementCertModal', lang);
                EventBus.$on('postponementDataSend', (data) => {
                    data.military_dispatch_status = lang == 'delay_1' ? 'delay' : lang
                    data.graduate_start_date ? data.graduate_start_date = moment(data.graduate_start_date).format('MM/DD/YYYY') : false
                    data.graduate_leave_date ? data.graduate_leave_date = moment(data.graduate_leave_date).format('MM/DD/YYYY') : false
                    data.date_of_postponement ? data.date_of_postponement = moment(data.date_of_postponement).format('MM/DD/YYYY') : false

                    StudentDocumentService.downloadDispatchPostponement(user.id, 'en', data).then(response => {
                        let fileName = user.student_number + '-' + i18n.t('file_name_dispatch_postponemen') + '-' + i18n.t(lang) + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', {
                            pdfFileData: response.data,
                            pdfFileName: fileName
                        });
                        EventBus.$emit('closePostponementCertModal', true);
                    }).catch(e => {
                        showErrors(e, null, true)
                    })
                })
            }
        })
    }
}

// Military Status
// if (['OL', 'L'].includes(user.program_level)) {
//     documents.push({
//         text: i18n.t('military_status_document'),
//         class: 'ri-article-line',
//         permission: 'studentdocument_militarystatus',
//         language: ['EN'],
//         callback: (lang) => {
//             StudentDocumentService.downloadMilitaryStatus(user.id, lang)
//                 .then(response => {
//                     let fileName = user.student_number + '-' + i18n.t('file_name_military_status') + '-' + lang + '.pdf'
//                     EventBus.$emit('pdfViewerModalShow', {
//                         pdfFileData: response.data,
//                         pdfFileName: fileName
//                     });
//                 })
//                 .catch(e => {
//                     showErrors(e, null, true)
//                 })
//         }
//     })
// }


return documents;

}

