<template>
  <div>
    <div>
      <ValidationObserver ref="formModalValidate">
        <b-col sm="12">
          <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
            <b-form-group :label="$t('academic_year')">
              <academic-years-selectbox
                  v-model="form.academic_year"
                  :validate-error="errors[0]"
              >

              </academic-years-selectbox>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col sm="12">
          <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
            <b-form-group :label="$t('semester')">
              <parameter-selectbox
                  code="semester"
                  v-model="form.semester"
                  :validate-error="errors[0]"
              ></parameter-selectbox>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </ValidationObserver>
    </div>
    <div class="d-flex justify-content-center mt-2">
      <b-button variant="primary" @click="studentAttendanceRecordsReport()">
        {{ $t('download') }}
      </b-button>
    </div>

  </div>

</template>

<script>
// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";
import qs from "qs";
import StudentDocumentService from "@/services/StudentDocumentService";
import {EventBus} from "@/main";
import showErrors from "@/helpers/showErrors";

export default {
  components: {
    AcademicYearsSelectbox,

    ValidationProvider,
    ValidationObserver
  },
  props: {
    studentProgramId: {
      type: Number
    },
    studentNumber: {
      type: String
    },

  },
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.$refs.formModalValidate.reset()
  },
  methods: {
    async studentAttendanceRecordsReport() {
      const isValid=await this.$refs.formModalValidate.validate()
      if (!isValid)return
      if(!this.checkPermission('studentdocument_dailyattendancelist')){
        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
      }
      let data={
        params:{
          filter:{
            ...this.form
          }
        },
        paramsSerializer: (params) => qs.stringify(params, {encode: false})
      }
      StudentDocumentService.studentAttendanceRecordsReport(this.studentProgramId,data)
          .then(response=>{
            const fileName=this.studentNumber + '-' + this.$t('student_attendance_records') + '.pdf'
            EventBus.$emit('pdfViewerModalShow', {
              pdfFileData: response.data,
              pdfFileName: fileName,
            })
          }).catch(e => {
        showErrors(e, null, true)
      })

    }

  }
}
</script>

